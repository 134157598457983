<script>
  import { boothStore } from "../../stores/data";
</script>

<style>

</style>

<div>
  <h2>{$boothStore.name}</h2>
  <h3>총 소득: {$boothStore.income}</h3>
</div>
